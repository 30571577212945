const Loading = () => {
  return (
    // <div className="absolute top-0 left-0 z-10 h-screen w-full bg-[#ffffffa3] flex justify-center items-center">
    <div className="w-fit p-[1vmax]">
      <div
        className="w-12 h-12 rounded-full animate-spin
    border-y-8 border-solid border-purple-500 border-t-transparent"
      ></div>
    </div>
  );
};

export default Loading;
