import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import Image from "mui-image";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import img1 from "./bkgdImages/aditya.png";
import img11 from "./bkgdImages/arav.png";
import img2 from "./bkgdImages/athena.jpg";
import img3 from "./bkgdImages/ayush.png";
import img4 from "./bkgdImages/evan.jpeg";
import img5 from "./bkgdImages/kevin.png";
import img6 from "./bkgdImages/mohit.jpeg";
import img7 from "./bkgdImages/raman.jpeg";
import img13 from "./bkgdImages/ronit.png";
import img12 from "./bkgdImages/sean.jpg";
import img8 from "./bkgdImages/shashank.png";
import img9 from "./bkgdImages/shivansh.png";
import img10 from "./bkgdImages/shraaptesh.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { toastOptions } from "../../Common/ToastStyle";

const Title = styled(Typography)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    margin-bottom: 1vw;
    text-align: center;
  }
`;

const TitleStack = styled(Stack)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7rem 5rem 7rem 5rem;
    background-color: transparent;
  }
`;

const SplitBoxesGrid = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    gap: 4.5vw;
    ${
      "" /* padding-top: 1rem;
    padding-bottom: 3rem; */
    }
    ${"" /* padding: 1rem 0rem 3rem 0rem; */}
  }
`;

const SubjectDescriptionGrid = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
    border: 1px solid grey;
  }
`;

const Founders = [
  {
    key: "ram",
    name: "Raman Arora",
    role: "Founder",
    image: img7,
  },
  {
    key: "adi",
    name: "Aditya Lagu",
    role: "Founder",
    image: img1,
  },
];

const PersonsCali = [
  {
    key: "shiv",
    name: "Shivansh Bansal",
    role: "Outreach Executive",
    image: img9,
  },
  {
    key: "evan",
    name: "Evan Fielding",
    role: "Engineering Lead",
    image: img4,
  },
  {
    key: "mohit",
    name: "Mohit Varikuti",
    role: "Treasurer",
    image: img6,
  },
  {
    key: "ayu",
    name: "Ayush Reddy",
    role: "Member",
    image: img3,
  },
  {
    key: "ath",
    name: "Athena Leonard",
    role: "Member",
    image: img2,
  },
  {
    key: "ronit",
    name: "Ronit Peswani",
    role: "Outreach Assistant",
    image: img13,
  },
  {
    key: "kau",
    name: "Kaushik Chandolu",
    role: "Member",
  },
  {
    key: "shanky",
    name: "Shashank Garag",
    role: "Past Member",
  },
  {
    key: "sudith",
    name: "Sudith Thota",
    role: "Past Member",
  },
];

const PersonsGeorgia = [
  {
    key: "shank",
    name: "Shashank Ramireddy",
    role: "Board Member",
    image: img8,
  },
  {
    key: "kev",
    name: "Kevin Ryu",
    role: "Board Member",
    image: img5,
  },
  {
    key: "arav",
    name: "Arav Raghu",
    role: "Member",
    image: img11,
  },
  {
    key: "sean",
    name: "Sean Lee",
    role: "Member",
    image: img12,
  },
  {
    key: "sarva",
    name: "Sarvajith Kujuluva",
    role: "Member",
  },
];

const PersonsNJ = [
  {
    key: "shraap",
    name: "Shraaptesh Lokanda",
    role: "Chapter Lead",
    image: img10,
  },
  {
    key: "aaravj",
    name: "Aarav Jain",
    role: "Assistant Lead",
  },
  {
    key: "ishaan",
    name: "Ishaan Khetarpal",
    role: "Workshop Coordinator",
  },
  {
    key: "anish",
    name: "Anish Yenduri",
    role: "Workshop Facilitator",
  },
  {
    key: "andrew",
    name: "Andrew Cui",
    role: "Workshop Facilitator",
  },
  {
    key: "pradh",
    name: "Pradhyu Ananthu",
    role: "Developer",
  },
  {
    key: "yejv",
    name: "Yejvan Madhanlal",
    role: "Outreach Coordinator",
  },
  {
    key: "khush",
    name: "Khushi Shah",
    role: "Outreach Coordinator",
  },
  {
    key: "anish",
    name: "Anish Yenduri",
    role: "Workshop Facilitator",
  },
  {
    key: "prat",
    name: "Prathamesh Lokanda",
    role: "Outreach Assistant",
  },
];

const DescriptionText = styled(Typography)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    ${"" /* padding-bottom: 2rem; */}
    color: white;
    transition: 0.25s all ease-in-out;
    zindex: 3;
    font-size: 1.25rem;
  }
`;

export default function PeopleDisplay() {
  const navigate = useNavigate();

  const hoverOn = (event) => {
    const key = event.currentTarget.role;
    const collection = document.querySelectorAll("#" + key);
    collection.forEach((item) => {
      item.style.opacity = "1";
    });

    const image = document.querySelector("#" + key + "IMAGE");
    if (image) {
      image.style.opacity = 0.2;
    }
  };

  const hoverOff = (event) => {
    const key = event.currentTarget.role;
    const collection = document.querySelectorAll("#" + key);
    collection.forEach((item) => {
      item.style.opacity = "0";
    });

    const image = document.querySelector("#" + key + "IMAGE");
    if (image) {
      image.style.opacity = 1;
    }
  };

  const [loading, setloading] = useState(false);
  const [allpatners, setallpatners] = useState([]);

  const getPatners = async (page) => {
    try {
      setloading(true);
      const { data } = await axios.get(`/api/patner/allpatners`);

      console.log(data);

      if (data.status) {
        console.log(data);
        setallpatners(data.patners);
      }
      setloading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something Bad Happen", toastOptions);
      setloading(false);
    }
  };

  useEffect(() => {
    getPatners();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center">
        {" "}
        <CircularProgress />{" "}
      </div>
    );
  } else
    return (
      <>
        <Title
          key="coursedisplays-1"
          variant="h3"
          paddingTop="4rem"
          marginBotton="0rem"
          sx={{ color: "#5383ec" }}
        >
          Founders
        </Title>
        <SplitBoxesGrid
          key="coursedisplays-2"
          container
          // divider={
          //   <Divider key="coursedisplays-3" orientation="vertical" flexItem />
          // }
          direction="row"
        >
          {allpatners
            .filter((patner) => patner.category === "Founders")
            .map((CourseCard) => (
              <SubjectDescriptionGrid
                key="coursedisplays-4"
                item
                xs={5}
                md={3}
                lg={2}
                role={CourseCard.name.split(" ")[0]}
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  height: { xs: "30vh", md: "30vh", lg: "30vh" },
                }}
                component={motion.div}
                initial={{
                  opacity: CourseCard.image ? 0 : 1,
                  scale: 0,
                }}
                whileInView={{
                  opacity: 1,
                  scale: 1,
                  // image:
                  //   "linear-gradient(to right top, #272727, #313131, #3b3b3b, #464646, #515151)",
                }}
                transition={{
                  duration: 0.3,
                  delay: 0,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
                whileHover={{
                  scale: 1.1,
                  // image:
                  //   "linear-gradient(to left top, #16283b, #2c3c4c, #213f62, #174177, #1c418a)",
                  // backgroundColor: "#03339c"
                  backgroundColor: "#003c88",
                }}
                onMouseOver={CourseCard.image ? hoverOn : null}
                onMouseOut={CourseCard.image ? hoverOff : null}
              >
                <div
                  key="coursedisplays-5"
                  style={{
                    position: "absolute",
                    display: CourseCard?.image ? "block" : "none",
                  }}
                >
                  <Image
                    sx={{ zIndex: 2, transition: "opacity 0s !important" }}
                    src={CourseCard.image}
                    // style={{ display: "none" }}
                    id={CourseCard.name.split(" ")[0] + "IMAGE"}
                  />
                </div>
                <TitleStack
                  key="coursedisplays-6"
                  sx={{ zIndex: 10, position: "absolute" }}
                >
                  <Title
                    variant="h4"
                    // sx={{ color: "white" }}
                    id={CourseCard.name.split(" ")[0]}
                    sx={{
                      opacity: CourseCard.image ? 0 : 1,
                      color: "white",
                    }}
                    key="coursedisplays-8"
                  >
                    {CourseCard.name}
                  </Title>
                  <DescriptionText
                    key="coursedisplays-9"
                    id={CourseCard.name.split(" ")[0]}
                    // variant="h5"
                    sx={{ opacity: CourseCard.image ? 0 : 1 }}
                  >
                    {CourseCard.role}
                  </DescriptionText>
                </TitleStack>
              </SubjectDescriptionGrid>
            ))}
        </SplitBoxesGrid>
        <Title
          key="coursedisplays-1"
          variant="h3"
          paddingTop="4rem"
          marginBotton="0rem"
          sx={{ color: "#5383ec" }}
        >
          California
        </Title>
        <SplitBoxesGrid
          key="coursedisplays-2"
          container
          // divider={
          //   <Divider key="coursedisplays-3" orientation="vertical" flexItem />
          // }
          direction="row"
        >
          {allpatners
            .filter((patner) => patner.category === "California")
            .map((CourseCard) => (
              <SubjectDescriptionGrid
                key="coursedisplays-4"
                item
                xs={5}
                md={3}
                lg={2}
                role={CourseCard.name.split(" ")[0]}
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  height: { xs: "30vh", md: "30vh", lg: "30vh" },
                }}
                component={motion.div}
                initial={{ opacity: CourseCard.image ? 0 : 1, scale: 0 }}
                whileInView={{
                  opacity: 1,
                  scale: 1,
                  // image:
                  //   "linear-gradient(to right top, #272727, #313131, #3b3b3b, #464646, #515151)",
                }}
                transition={{
                  duration: 0.3,
                  delay: 0,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
                whileHover={{
                  scale: 1.1,
                  // image:
                  //   "linear-gradient(to left top, #16283b, #2c3c4c, #213f62, #174177, #1c418a)",
                  // backgroundColor: "#03339c"
                  backgroundColor: "#003c88",
                }}
                onMouseOver={CourseCard.image ? hoverOn : null}
                onMouseOut={CourseCard.image ? hoverOff : null}
              >
                <div
                  key="coursedisplays-5"
                  style={{
                    position: "absolute",
                    display: CourseCard?.image ? "block" : "none",
                  }}
                >
                  <Image
                    sx={{ zIndex: 2, transition: "opacity 0s !important" }}
                    src={CourseCard.image}
                    // style={{ display: "none" }}
                    id={CourseCard.name.split(" ")[0] + "IMAGE"}
                  />
                </div>
                <TitleStack
                  key="coursedisplays-6"
                  sx={{ zIndex: 10, position: "absolute" }}
                >
                  <Title
                    variant="h4"
                    // sx={{ color: "white" }}
                    id={CourseCard.name.split(" ")[0]}
                    sx={{
                      opacity: CourseCard.image ? 0 : 1,
                      color: "white",
                    }}
                    key="coursedisplays-8"
                  >
                    {CourseCard.name}
                  </Title>
                  <DescriptionText
                    key="coursedisplays-9"
                    id={CourseCard.name.split(" ")[0]}
                    // variant="h5"
                    sx={{ opacity: CourseCard.image ? 0 : 1 }}
                  >
                    {CourseCard.role}
                  </DescriptionText>
                </TitleStack>
              </SubjectDescriptionGrid>
            ))}
        </SplitBoxesGrid>
        <Title
          key="coursedisplays-1"
          variant="h3"
          paddingTop="4rem"
          marginBotton="0rem"
          sx={{ color: "#5383ec" }}
        >
          Georgia
        </Title>
        <SplitBoxesGrid
          key="coursedisplays-2"
          container
          // divider={
          //   <Divider key="coursedisplays-3" orientation="vertical" flexItem />
          // }
          direction="row"
        >
          {allpatners
            .filter((patner) => patner.category === "Georgia")
            .map((CourseCard) => (
              <SubjectDescriptionGrid
                key="coursedisplays-4"
                item
                xs={5}
                md={3}
                lg={2}
                role={CourseCard.name.split(" ")[0]}
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  height: { xs: "30vh", md: "30vh", lg: "30vh" },
                }}
                component={motion.div}
                initial={{ opacity: CourseCard.image ? 0 : 1, scale: 0 }}
                whileInView={{
                  opacity: 1,
                  scale: 1,
                  // image:
                  //   "linear-gradient(to right top, #272727, #313131, #3b3b3b, #464646, #515151)",
                }}
                transition={{
                  duration: 0.3,
                  delay: 0,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
                whileHover={{
                  scale: 1.1,
                  // image:
                  //   "linear-gradient(to left top, #16283b, #2c3c4c, #213f62, #174177, #1c418a)",
                  // backgroundColor: "#03339c"
                  backgroundColor: "#003c88",
                }}
                onMouseOver={CourseCard.image ? hoverOn : null}
                onMouseOut={CourseCard.image ? hoverOff : null}
              >
                <div
                  key="coursedisplays-5"
                  style={{
                    position: "absolute",
                    display: CourseCard?.image ? "block" : "none",
                  }}
                >
                  <Image
                    sx={{ zIndex: 2, transition: "opacity 0s !important" }}
                    src={CourseCard.image}
                    // style={{ display: "none" }}
                    id={CourseCard.name.split(" ")[0] + "IMAGE"}
                  />
                </div>
                <TitleStack
                  key="coursedisplays-6"
                  sx={{ zIndex: 10, position: "absolute" }}
                >
                  <Title
                    variant="h4"
                    // sx={{ color: "white" }}
                    id={CourseCard.name.split(" ")[0]}
                    sx={{
                      opacity: CourseCard.image ? 0 : 1,
                      color: "white",
                    }}
                    key="coursedisplays-8"
                  >
                    {CourseCard.name}
                  </Title>
                  <DescriptionText
                    key="coursedisplays-9"
                    id={CourseCard.name.split(" ")[0]}
                    // variant="h5"
                    sx={{ opacity: CourseCard.image ? 0 : 1 }}
                  >
                    {CourseCard.role}
                  </DescriptionText>
                </TitleStack>
              </SubjectDescriptionGrid>
            ))}
        </SplitBoxesGrid>
        <Title
          key="coursedisplays-1"
          variant="h3"
          paddingTop="4rem"
          marginBotton="0rem"
          sx={{ color: "#5383ec" }}
        >
          New Jersey
        </Title>
        <SplitBoxesGrid
          key="coursedisplays-2"
          container
          // divider={
          //   <Divider key="coursedisplays-3" orientation="vertical" flexItem />
          // }
          direction="row"
        >
          {allpatners
            .filter((patner) => patner.category === "New Jersey")
            .map((CourseCard) => (
              <SubjectDescriptionGrid
                key="coursedisplays-4"
                item
                xs={5}
                md={3}
                lg={2}
                role={CourseCard.name.split(" ")[0]}
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  height: { xs: "30vh", md: "30vh", lg: "30vh" },
                }}
                component={motion.div}
                initial={{ opacity: CourseCard.image ? 0 : 1, scale: 0 }}
                whileInView={{
                  opacity: 1,
                  scale: 1,
                  // image:
                  //   "linear-gradient(to right top, #272727, #313131, #3b3b3b, #464646, #515151)",
                }}
                transition={{
                  duration: 0.3,
                  delay: 0,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
                whileHover={{
                  scale: 1.1,
                  // image:
                  //   "linear-gradient(to left top, #16283b, #2c3c4c, #213f62, #174177, #1c418a)",
                  // backgroundColor: "#03339c"
                  backgroundColor: "#003c88",
                }}
                onMouseOver={CourseCard.image ? hoverOn : null}
                onMouseOut={CourseCard.image ? hoverOff : null}
              >
                <div
                  key="coursedisplays-5"
                  style={{
                    position: "absolute",
                    display: CourseCard?.image ? "block" : "none",
                  }}
                >
                  <Image
                    sx={{ zIndex: 2, transition: "opacity 0s !important" }}
                    src={CourseCard.image}
                    id={CourseCard.name.split(" ")[0] + "IMAGE"}
                  />
                </div>
                <TitleStack
                  key="coursedisplays-6"
                  sx={{ zIndex: 10, position: "absolute" }}
                >
                  <Title
                    variant="h4"
                    // sx={{ color: "white" }}
                    id={CourseCard.name.split(" ")[0]}
                    sx={{
                      opacity: CourseCard.image ? 0 : 1,
                      color: "white",
                    }}
                    key="coursedisplays-8"
                  >
                    {CourseCard.name}
                  </Title>
                  <DescriptionText
                    key="coursedisplays-9"
                    id={CourseCard.name.split(" ")[0]}
                    // variant="h5"
                    sx={{ opacity: CourseCard.image ? 0 : 1 }}
                  >
                    {CourseCard.role}
                  </DescriptionText>
                </TitleStack>
              </SubjectDescriptionGrid>
            ))}
        </SplitBoxesGrid>
      </>
    );
}
