import { ThemeProvider } from "@mui/material";
import axios from "axios";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AboutTitle from "./aboutUs/aboutTitle.jsx";
import OurStory from "./aboutUs/ourStory.jsx";
import PeopleDisplay from "./aboutUs/peopleDisplay.jsx";
import Nav from "./website-constants/NavBarLoggedOut";
import theme from "./website-constants/Theme.jsx";
import { toastOptions } from "../Common/ToastStyle.js";
import { toast } from "react-toastify";
export default function AboutUs() {
  const prevOver = document.body.style.overflow;
  document.body.style.overflow = prevOver;
  // document.body.style.overflow = "none";

  const navigate = useNavigate();

 

  useLayoutEffect(() => {
    axios
      .post("/api/auth/authenticate", {
        withCredentials: true,
        credentials: "include",
      })
      .then((res) => {
        navigate("/courses");
      })
      .catch((err) => {
        console.log("Welcome");
      });
  }, []);

  return (
    <>
      <ThemeProvider theme={theme} border="none" key="landing-1">
        <Nav key="about-2" />
        <AboutTitle key="about-title"></AboutTitle>
        <PeopleDisplay key="people-display"></PeopleDisplay>
        <OurStory key="landing-5"></OurStory>
        {/* <PartnerDisplay key="landing-6"></PartnerDisplay> */}
      </ThemeProvider>
    </>
  );
}
