import { RxCross2 } from "react-icons/rx";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TiTick } from "react-icons/ti";
import { FaPlus } from "react-icons/fa6";
import { toastOptions } from "../../../Common/ToastStyle";

const EditUser = ({
  seteditusermodal,
  edituserinfo,
  allusers,
  setallusers,
}) => {
  const [sampletopics, setsampletopics] = useState([]);

  const usersstate = useSelector((state) => state.users);

  const [info, setinfo] = useState(edituserinfo);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setinfo({
      ...info,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`/api/user/updatedetails`, { ...info })
      .then(({ data }) => {
        console.log(data);

        const updateduser = data.user;

        const userIndex = allusers.findIndex(
          (user) => user._id === edituserinfo._id
        );

        if (userIndex !== -1) {
          let updatedusers = [...allusers];

          updatedusers[userIndex] = updateduser;

          setallusers(updatedusers);

          toast.success("User Updated Successfully", toastOptions);
        }

        seteditusermodal(false);
      })
      .catch((error) => {
        toast.success("Something Bad happen!", toastOptions);
        console.log(error);
      });

    console.log(info);
  };

  return (
    <div className="fixed top-0 left-0 h-screen w-full z-50 bg-[#e5e7ebbb] flex justify-center items-center">
      <div className="bg-white rounded-lg p-4 relative shadow-[0_0_10px_#00000075]  ">
        <h2 className="text-2xl font-semibold leading-tight mb-2">
          Edit User Details
        </h2>

        <form
          className="w-[450px] max-h-[70vh] flex flex-col gap-[1vmin] overflow-auto"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-2">
            <label htmlFor="">Username</label>
            <input
              type="text"
              name="username"
              id="username"
              placeholder="John"
              className=" p-2 border rounded-lg  "
              value={info.username}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="">Email</label>
            <input
              type="text"
              name="email"
              id="email"
              placeholder="Email@example.com"
              className=" p-2 border rounded-lg  "
              value={info.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="">Role</label>
            <select
              id="role"
              name="role"
              value={info.role}
              onChange={handleInputChange}
              className=" p-2 border rounded-lg "
            >
              <option value="user">user</option>
              <option value="admin">admin</option>
            </select>
          </div>

          <button
            type="submit"
            className="text-2xl p-2 mt-4 border-[1px] bg-[#DE1D13] text-white "
          >
            Submit
          </button>
        </form>

        <button
          className="absolute top-4 right-4 "
          onClick={() => seteditusermodal(false)}
        >
          <RxCross2 className="text-xl" />
        </button>
      </div>
    </div>
  );
};

export default EditUser;
