import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./pages/website-constants/Theme.jsx";
import Courses from "./pages/Courses.jsx";
import axios from "axios";
import Loading from "./pages/website-constants/loading.jsx";
import NotFound from "./pages/website-constants/notfoundpage.jsx";
import { ToastContainer } from "react-toastify";
import AuthRoutes from "./components/AuthRoutes.jsx";
import { useDispatch } from "react-redux";
import { setLogin, setUser } from "./store/slice/userSlice.js";
import Admin from "./pages/admin/Admin.jsx";
import AboutUs from "./pages/aboutUs.jsx";
const MathLearningPath = lazy(() => import("./pages/mathlearningpath.jsx"));
const BioLearningPath = lazy(() => import("./pages/biolearningpath.jsx"));
const Landing = lazy(() => import("./pages/Landing"));
const Signup = lazy(() => import("./pages/Signup"));
const Login = lazy(() => import("./pages/Login"));
const BiotechnologyPage = lazy(() => import("./pages/biotechnology"));
const AppliedMathPage = lazy(() => import("./pages/appliedmath"));

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const [loading, setloading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    setloading(true);
    axios
      .post("/api/auth/isauth", {
        withCredentials: true,
        credentials: "include",
      })
      .then(({ data }) => {
        console.log(data);
        if (data.status) {
          dispatch(setUser(data.user));
          dispatch(setLogin(true));
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }

        setloading(false);
      })
      .catch((err) => {
        setLoggedIn(false);

        console.log(err);
        setloading(false);
      });
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <BrowserRouter>
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route
                  path="/login"
                  element={
                    loggedIn === true ? <Navigate to="/courses" /> : <Login />
                  }
                />

                <Route
                  path="/dashboard"
                  element={
                    <AuthRoutes loggedIn={loggedIn} roles={["admin"]}>
                      <Admin />
                    </AuthRoutes>
                  }
                />

                <Route path="/aboutus" element={<AboutUs />} />

                <Route
                  path="/biotechnology/:lessonid"
                  element={
                    <AuthRoutes loggedIn={loggedIn}>
                      <BioLearningPath />
                    </AuthRoutes>
                  }
                />

                <Route
                  path="/courses"
                  element={
                    <AuthRoutes loggedIn={loggedIn}>
                      <Courses />
                    </AuthRoutes>
                  }
                />

                <Route
                  path="/engineering/:lessonid"
                  element={
                    <AuthRoutes loggedIn={loggedIn}>
                      <MathLearningPath />
                    </AuthRoutes>
                  }
                />

                <Route
                  path="/"
                  element={
                    loggedIn === false ? (
                      <Landing />
                    ) : (
                      <Navigate to="/courses" />
                    )
                  }
                />

                <Route
                  path="/signup"
                  element={
                    loggedIn === false ? <Signup /> : <Navigate to="/courses" />
                  }
                />

                <Route
                  path="/biotechnology"
                  element={
                    <AuthRoutes loggedIn={loggedIn}>
                      <BiotechnologyPage />
                    </AuthRoutes>
                  }
                />

                <Route
                  path="/engineering"
                  element={
                    <AuthRoutes loggedIn={loggedIn}>
                      <AppliedMathPage />
                    </AuthRoutes>
                  }
                />

                <Route path="*" element={<NotFound></NotFound>} />
              </Routes>
              <ToastContainer />
            </Suspense>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
