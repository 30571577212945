const userTableColumns = [
  { label: "Id", key: "_id" },
  { label: "Username", key: "username" },
  { label: "Email", key: "email" },
  { label: "Role", key: "role" },
  { label: "Created On", key: "createdAt" },
  { label: "Action", key: "edit" },
];

const defaultActiveUsersColumns = ["Username", "Email", "Role", "Action"];

export { userTableColumns, defaultActiveUsersColumns };
