import { RxCross2 } from "react-icons/rx";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TiTick } from "react-icons/ti";
import { FaPlus } from "react-icons/fa6";
import { toastOptions } from "../../../Common/ToastStyle";
import { companyCategory, companyRole } from "./mockdata";

const EditCompany = ({
  seteditcompanymodal,
  editcompanyinfo,
  allcompanys,
  setallcompanys,
}) => {
  const [sampletopics, setsampletopics] = useState([]);

  const companysstate = useSelector((state) => state.companys);

  const [info, setinfo] = useState(editcompanyinfo);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setinfo({
      ...info,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`/api/company/updatedetails`, { ...info })
      .then(({ data }) => {
        console.log(data);

        if (data.status) {
          const updatedcompany = data.company;

          const companyIndex = allcompanys.findIndex(
            (company) => company._id === editcompanyinfo._id
          );

          if (companyIndex !== -1) {
            let updatedcompanys = [...allcompanys];

            updatedcompanys[companyIndex] = updatedcompany;

            setallcompanys(updatedcompanys);

            toast.success("Company Updated Successfully", toastOptions);
          }
        }
        seteditcompanymodal(false);
      })
      .catch((error) => {
        toast.success("Something Bad happen!", toastOptions);
        console.log(error);
      });

    console.log(info);
  };

  return (
    <div className="fixed top-0 left-0 h-screen w-full z-50 bg-[#e5e7ebbb] flex justify-center items-center">
      <div className="bg-white rounded-lg p-4 relative shadow-[0_0_10px_#00000075]  ">
        <h2 className="text-2xl font-semibold leading-tight mb-2">
          Edit Company Details
        </h2>

        <form
          className="w-[450px] max-h-[70vh] flex flex-col gap-[1vmin] overflow-auto"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-2">
            <label htmlFor="">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="John"
              className=" p-2 border rounded-lg  "
              value={info.name}
              onChange={handleInputChange}
            />
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="">Image Url</label>
            <div className="h-[150px] w-full bg-slate-100 rounded-lg flex justify-center items-center">
              {info.image ? (
                <img src={info.image} alt="" className="w-[150px]" />
              ) : (
                <div>Add Image link</div>
              )}
            </div>
            <input
              type="text"
              name="image"
              id="image"
              placeholder="John"
              className=" p-2 border rounded-lg  "
              value={info.image}
              onChange={handleInputChange}
            />
          </div>

          <button
            type="submit"
            className="text-2xl p-2 mt-4 border-[1px] bg-[#DE1D13] text-white "
          >
            Submit
          </button>
        </form>

        <button
          className="absolute top-4 right-4 "
          onClick={() => seteditcompanymodal(false)}
        >
          <RxCross2 className="text-xl" />
        </button>
      </div>
    </div>
  );
};

export default EditCompany;
