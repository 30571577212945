const companyTableColumns = [
  { label: "Id", key: "_id" },
  { label: "Image", key: "image" },
  { label: "Name", key: "name" },
  { label: "Created On", key: "createdAt" },
  { label: "Action", key: "edit" },
];

const defaultActiveCompanysColumns = ["Image", "Name", "Action"];

export { companyTableColumns, defaultActiveCompanysColumns };
