import axios, { all } from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";

import EditPatner from "./EditPatner";
import { defaultActivePatnersColumns, patnerTableColumns } from "./mockdata";
import { toastOptions } from "../../../Common/ToastStyle";
import DeletePatner from "./DeletePatner";
import { RxCross2 } from "react-icons/rx";
import AddPatner from "./AddPatner";
import Loading from "../../../components/Loading";

const PatnersMenu = () => {
  const limit = 10;
  const dispatch = useDispatch();

  const [columns, setcolumns] = useState(defaultActivePatnersColumns);

  const [modal, setmodal] = useState(false);

  const [allpatners, setallpatners] = useState([]);

  const [editpatnermodal, seteditpatnermodal] = useState(false);
  const [editpatnerinfo, seteditpatnerinfo] = useState({});

  const [deletepatnermodal, setdeletepatnermodal] = useState(false);
  const [deletepatnerid, setdeletepatnerid] = useState({});

  const [addpatnermodal, setaddpatnermodal] = useState(false);

  const [page, setpage] = useState(1);
  const [name, setname] = useState("");

  const [loading, setloading] = useState(false);

  const getPatners = async (page) => {
    try {
      setloading(true);
      const { data } = await axios.get(`/api/patner/allpatners`);

      console.log(data);

      if (data.status) {
        console.log(data);
        setallpatners(data.patners);
      }
      setloading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something Bad Happen", toastOptions);
      setloading(false);
    }
  };

  useEffect(() => {
    getPatners();
  }, []);

  return (
    <div className="container mx-auto p-[1vmax]">
      <div className="flex gap-[1vmin] flex-col overflow-x-auto ">
        <div className="flex justify-between pt-[1vmin] pr-[1vmin]">
          <div className="w-full flex items-center justify-between">
            <div className="flex gap-2 items-center rounded-lg border-2 border-gray-400 w-fit bg-white p-2 px-4 ">
              <svg
                className="w-4 h-4 text-black"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <input
                type="email"
                className="border-none outline-none"
                placeholder="Search by Name"
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
              {name && (
                <button
                  className="bg-red-500 p-1 rounded-lg text-white font-bold"
                  onClick={() => setname("")}
                >
                  <RxCross2 />
                </button>
              )}
            </div>
            <button
              className="p-1 px-4 bg-blue-500 rounded-lg font-bold"
              onClick={() => setaddpatnermodal(true)}
            >
              Add
            </button>
          </div>
        </div>
        <div className={`overflow-auto flex gap-[1vmin] flex-wrap `}>
          {patnerTableColumns.map((column, i) => {
            return (
              <div
                key={i}
                className="flex gap-2 bg-slate-700 text-white p-1 px-4 text-lg rounded-lg"
              >
                <input
                  type="checkbox"
                  id={column.key}
                  checked={
                    columns.find((col) => col === column.label) ? true : false
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      const newcolums = [...columns, column.label];
                      setcolumns(newcolums);
                    } else {
                      const newcolums = columns.filter(
                        (obj) => obj !== column.label
                      );
                      setcolumns(newcolums);
                    }
                  }}
                />
                <label htmlFor={column.key} className="w-max">
                  {column.label}
                </label>
              </div>
            );
          })}
        </div>
        <div className="overflow-auto">
          <table className="w-full text-black rounded-[1vmin] overflow-hidden text-left">
            <thead className="text-xs text-white uppercase  bg-gray-800 ">
              <tr>
                {patnerTableColumns
                  .filter((selectedcols) =>
                    columns.find((obj) => selectedcols.label === obj)
                  )
                  .map((column, i) => {
                    return (
                      <th key={i} scope="col" className="font-bold p-[1vmax] ">
                        {column.label}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {allpatners.length > 0 &&
                allpatners
                  .filter((patner) =>
                    name ? patner.name.includes(name) : true
                  )
                  .map((patner, i) => {
                    return (
                      <tr key={i} className="bg-white border hover:bg-blue-50 ">
                        {patnerTableColumns
                          .filter((selectedcols) =>
                            columns.find((obj) => selectedcols.label === obj)
                          )
                          .map((column, i) => {
                            if (column.key === "edit")
                              return (
                                <td className="p-[1vmax] flex gap-2" key={i}>
                                  <button
                                    className="bg-blue-600 text-white p-[0.5vmin_1vmax] rounded-[1vmin]"
                                    onClick={() => {
                                      seteditpatnermodal(true);
                                      seteditpatnerinfo(patner);
                                    }}
                                  >
                                    {" "}
                                    Edit{" "}
                                  </button>
                                  <button
                                    className="bg-red-600 text-white p-[0.5vmin_1vmax] rounded-[1vmin]"
                                    onClick={() => {
                                      setdeletepatnermodal(true);
                                      setdeletepatnerid(patner._id);
                                    }}
                                  >
                                    {" "}
                                    Delete{" "}
                                  </button>
                                </td>
                              );
                            else if (column.key === "image")
                              return (
                                <td key={i} className="p-[1vmax]">
                                  <img
                                    src={patner.image}
                                    alt=""
                                    className="h-[40px]"
                                  />
                                </td>
                              );
                            else if (column.key === "createdAt")
                              return (
                                <td key={i} className="p-[1vmax]">
                                  {patner?.createdAt
                                    ? `${new Date(
                                        patner?.createdAt
                                      ).toDateString()} : ${
                                        new Date(patner?.createdAt)
                                          .toTimeString()
                                          .split(" ")[0]
                                      }`
                                    : ""}
                                </td>
                              );
                            else
                              return (
                                <td className="p-[1vmax]" key={i}>
                                  <div className="w-max">
                                    {patner[column.key]}
                                  </div>
                                </td>
                              );
                          })}
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {loading && (
            <div className="bg-white w-full flex justify-center">
              <Loading />
            </div>
          )}
        </div>
      </div>

      {editpatnermodal && (
        <EditPatner
          seteditpatnermodal={seteditpatnermodal}
          editpatnerinfo={editpatnerinfo}
          allpatners={allpatners}
          setallpatners={setallpatners}
        />
      )}
      {deletepatnermodal && (
        <DeletePatner
          setdeletepatnermodal={setdeletepatnermodal}
          deletepatnerid={deletepatnerid}
          allpatners={allpatners}
          setallpatners={setallpatners}
        />
      )}
      {addpatnermodal && (
        <AddPatner
          setaddpatnermodal={setaddpatnermodal}
          allpatners={allpatners}
          setallpatners={setallpatners}
        />
      )}
    </div>
  );
};

export default PatnersMenu;
