import {
  Typography
} from "@mui/material";
import { React } from "react";
import styled from "styled-components";

const TitleText = styled(Typography)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    margin-top: 2.5rem;
    text-align: center;
    color: 'white';
    final: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 2
      }
    }
    box-shadow: "black";
  }
`;

export default function AboutTitle() {
  return (
    <>
      <TitleText
        sx={{
          color: "#5383ec",
          fontSize: { xs: "9vw", md: "6vw" },
        }}
        key="aMathTitle-TitleText"
      >
        About Us
      </TitleText>
    </>
  );
}
