import { RxCross2 } from "react-icons/rx";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TiTick } from "react-icons/ti";
import { FaPlus } from "react-icons/fa6";
import { toastOptions } from "../../../Common/ToastStyle";

const DeletePatner = ({
  setdeletepatnermodal,
  deletepatnerid,
  allpatners,
  setallpatners,
}) => {
  const [info, setinfo] = useState(deletepatnerid);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setinfo({
      ...info,
      [name]: value,
    });
  };

  const handleDelete = async () => {
    try {
      const { data } = await axios.post("/api/patner/delete", {
        id: deletepatnerid,
      });

      if (data.status) {
        setallpatners((data) => {
          const updated = data.filter((patner) => deletepatnerid !== patner._id);

          return updated;
        });

        toast.success("Patner Deleted Successfully", toastOptions);
        setdeletepatnermodal(false);
      }
    } catch (error) {
      toast.warn("Some Problem happen!", toastOptions);
      setdeletepatnermodal(false);
    }
  };

  return (
    <div className="fixed top-0 left-0 h-screen w-full z-10 bg-[#e5e7ebbb] flex justify-center items-center">
      <div className="bg-white rounded-lg p-4 pt-8 relative shadow-[0_0_10px_#00000075] flex flex-col gap-6  ">
        <h2 className="text-2xl text-center font-semibold leading-tight">
          Are you sure ?
        </h2>

        <div className="w-[450px] flex flex-col gap-6 ">
          <div className="flex flex-col gap-2 text-center">
            {" "}
            Do you really want to delete this record ? This process cannot be
            undone.
          </div>

          <div className="grid grid-cols-2 gap-2">
            <button
              type="submit"
              className="text-2xl p-1 border-[1px] bg-[#DE1D13] text-white rounded-lg "
              onClick={() => handleDelete()}
            >
              Delete
            </button>
            <button
              type="submit"
              className="text-2xl p-1  border-[1px] bg-slate-500 text-white rounded-lg "
              onClick={() => setdeletepatnermodal(false)}
            >
              Cancel
            </button>
          </div>
        </div>

        <button
          className="absolute top-4 right-4 "
          onClick={() => setdeletepatnermodal(false)}
        >
          <RxCross2 className="text-xl" />
        </button>
      </div>
    </div>
  );
};

export default DeletePatner;
