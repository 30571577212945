import { Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import Image from "mui-image";
import React from "react";
import styled from "styled-components";
import teamImg from "./team.svg";
import worldImg from "./world.svg";

const OurMissionGrid = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem 2rem 0rem;
    gap: 4vw;
    ${"" /* height: 60vh; */}
  }
`;

const OurMissionTextGrid = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const OurMissionImgGrid = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const OurMissionImg = styled(Image)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }
`;

const OurMissionText = styled(Typography)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 100;
    padding: 3rem 3rem 3rem 3rem;
    text-align: center;
    color: white;
    text-align: left;
  }
`;

const BiomathText = styled(Typography)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    ${"" /* margin-bottom: 1rem; */}
    text-align: center;
    color: #5383ec;
    padding-top: 5rem;
  }
`;

export default function OurStory() {
  return (
    <>
      <BiomathText key="ourmission-6" variant="h3" paddingTop="5rem">
        Our Story
      </BiomathText>
      <OurMissionGrid container key="ourmission-1">
        <OurMissionTextGrid
          key="ourmission-4"
          Item
          xs={11}
          md={5}
          component={motion.div}
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 1,
            delay: 0,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          {/* <Stack key="ourmission-5"> */}
            <OurMissionText variant="h5" key="ourmission-7">
            Our two founders, Aditya and Raman, have been very close friends as they both went to the same middle school, but Aditya abruptly moved to Georgia at the start of high school. Yet, their bond remained strong despite being on opposite sides of the country, and they continued to share a passion for global change through entrepreneurship. At each of their respective schools, Aditya and Raman noticed a significant lack of opportunities for students to explore and gain insights into career pathways within STEM. This was especially concerning given that few schools offer specific engineering or biomedical pathways, leading to a majority of students being ignorant of the vast number of career opportunities available and thus unprepared to pick majors and decide their careers.
            </OurMissionText>
          {/* </Stack> */}
        </OurMissionTextGrid>
        <OurMissionImgGrid
          key="ourmission-2"
          item
          xs={10}
          md={4}
          component={motion.div}
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 1,
            delay: 0,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <Image key="ourmission-3" src={teamImg} />
        </OurMissionImgGrid>
      </OurMissionGrid>
      <OurMissionGrid container key="ourmission-1">
      <OurMissionImgGrid
          key="ourmission-2"
          item
          xs={10}
          md={4}
          component={motion.div}
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 1,
            delay: 0,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <Image key="ourmission-3" src={worldImg} />
        </OurMissionImgGrid>
        <OurMissionTextGrid
          key="ourmission-4"
          Item
          xs={11}
          md={5}
          component={motion.div}
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 1,
            delay: 0,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          {/* <Stack key="ourmission-5"> */}
            <OurMissionText variant="h5" key="ourmission-7">
              Driven by a shared objective to address this gap, Aditya and Raman decided to combine their interests in STEM—Aditya's enthusiasm for biotechnology and Raman's passion for engineering and math. They initially coded and created a website to serve as a platform for accessing STEM courses on projcatalyst.com. Realizing the potential for greater impact, they expanded their vision and founded a full-fledged nonprofit organization implemented throughout the nation. Their mission is to provide equitable access to STEM education internationally to ensure that people from all backgrounds have the opportunity to discover their underlying interests in these fields.
            </OurMissionText>
          {/* </Stack> */}
        </OurMissionTextGrid>
      </OurMissionGrid>
    </>
  );
}
