import React, { useState } from "react";

import UsersMenu from "./Users/UsersMenu";
import PatnersMenu from "./Patners/PatnersMenu";
import CompanysMenu from "./Companies/CompanysMenu";

const Admin = () => {
  const [menu, setmenu] = useState(0);

  return (
    <div className="grid grid-cols-[20%_80%] min-h-screen ">
      <div className="h-screen bg-[#34385e] text-white p-4 border-r border-[#ffffff90]">
        <div className="text-xl font-bold">Dashboard</div>
        <ul className="flex flex-col gap-2 mt-3">
          <li
            className={`p-3 rounded-lg hover:border hover:border-white ${
              menu === 0 ? "bg-slate-800" : "bg-slate-700"
            }`}
            onClick={() => setmenu(0)}
          >
            Users
          </li>
          <li
            className={`p-3 rounded-lg hover:border hover:border-white ${
              menu === 1 ? "bg-slate-800" : "bg-slate-700"
            }`}
            onClick={() => setmenu(1)}
          >
            Patners
          </li>
          <li
            className={`p-3 rounded-lg hover:border hover:border-white ${
              menu === 2 ? "bg-slate-800" : "bg-slate-700"
            }`}
            onClick={() => setmenu(2)}
          >
            Companies
          </li>
        </ul>
      </div>
      <div className="">
        {menu === 0 && <UsersMenu />}
        {menu === 1 && <PatnersMenu />}
        {menu === 2 && <CompanysMenu />}
      </div>
    </div>
  );
};

export default Admin;
