import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastOptions } from "../Common/ToastStyle";
import { useSelector } from "react-redux";

const AuthRoutes = ({ children, loggedIn, roles }) => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(true);
  const userstate = useSelector((state) => state.user);

  if (userstate.isLogin) {
    if (roles && !roles.includes(userstate.user.role)) {
      toast.warn(
        "UnAuthorised Access Error, You are not Authorised to access page content",
        toastOptions
      );
      return <Navigate to="/" />;
    } else {
      return children;
    }
  } else {
    // toast.error("UnAuthenticated Access! Please Login ", toastOptions);
    return <Navigate to="/login" />;
  }
};

export default AuthRoutes;
