const patnerTableColumns = [
  { label: "Id", key: "_id" },
  { label: "Image", key: "image" },
  { label: "Name", key: "name" },
  { label: "Role", key: "role" },
  { label: "Category", key: "role" },
  { label: "Created On", key: "createdAt" },
  { label: "Action", key: "edit" },
];

const defaultActivePatnersColumns = [
  "Image",
  "Name",
  "Role",
  "Category",
  "Action",
];

const patnerRole = [
  "Founder",
  "Outreach Executive",
  "Engineering Lead",
  "Treasurer",
];

const patnerCategory = ["Founders", "California", "Georgia", "New Jersey"];

export {
  patnerTableColumns,
  defaultActivePatnersColumns,
  patnerRole,
  patnerCategory,
};
